.menu-list {
  &-item {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }

  &-content {
    display: flex;
    gap: 16px;
  }

  &-icon {
    font-size: 24px;
  }

  &-content {
    .fs-16-700;
    color: @c-black-100;
    text-transform: uppercase;
  }
}
