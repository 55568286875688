.desktop-layout {
  display: none;
}

.mobile-layout {
  display: flex;
}

@media screen and (min-width: 900px) {
  .mobile-layout {
    display: none;
  }

  .desktop-layout {
    display: flex;
    height: 100%;
  }

  .desktop-layout-sider {
    max-width: 300px;
    background-color: @c-white-100;
    overflow: scroll;
  }

  .desktop-layout-body {
    flex: 1;
  }
}
