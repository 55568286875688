.ant-input {
  background: @c-gray-1;
  border-color: @c-gray-1;
  border-radius: 4px;

  &::placeholder {
    .fs-14;
    color: @c-gray-4;
  }
}

.ant-input:focus, .ant-input-focused {
  box-shadow: unset;
  border-color: @c-primary !important;
  background-color: @c-primary-10 !important;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border-color: #F7F7F7;
  background-color: #F7F7F7;

  &-focused {
    box-shadow: unset;
    border-color: @c-primary !important;
    background-color: @c-primary-10 !important;

    input.ant-input {
      background: transparent !important;
    }
  }
}
