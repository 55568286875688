html * {
  font-family: 'Neue-Einstellung', sans-serif;
}

body {
  overscroll-behavior: contain;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1,
.h1 {
  color: @c-black-100;
  .fs-32-700;
  line-height: 135%;
  margin: 0;
  padding: 0;

  &--small{
    .fs-40-700;
  }
}

h2,
.h2 {
  color: @c-black-100;
  .fs-24-700;
  line-height: 135%;
}

h3,
.h3 {
  color: @c-black-100;
  font-style: normal;
  .fs-20-700;
  line-height: 30px;
}

h4,
.h4 {
  color: @c-black-100;
  font-style: normal;
  .fs-20-700;
  line-height: 150%;
}

h5,
.h5 {
  color: @c-black-100;
  .fs-18-700;
  line-height: 150%;
}

h6,
.h6{
  color: @c-black-100;
  .fs-16-700;
  line-height: 150%;
}

h7,
.h7{
  color: @c-black-100;
  .fs-14-700;
  line-height: 150%;
}

h8,
.h8{
  color: @c-black-100;
  .fs-12-700;
  line-height: 150%;
}

a {
  .clickable;
  text-decoration: underline;
}


//@media screen and (max-width: @mobile-breakpoint) {
//  h1,
//  .h1 {
//    .fs-28-700;
//    line-height: 140%;
//    &--small{
//      .fs-24-700;
//    }
//  }
//
//  h2,
//  .h2 {
//    .fs-22-700;
//    line-height: 140%;
//  }
//
//  h3,
//  .h3 {
//    font-style: normal;
//    .fs-18-700;
//    line-height: 21px;
//  }
//
//  h4,
//  .h4 {
//    font-style: normal;
//    .fs-16-700;
//    line-height: 150%;
//  }
//
//  h5,
//  .h5 {
//    .fs-14-700;
//    line-height: 150%;
//  }
//}

