@base-font-size: 16;

.rem(@selector, @pixels) {
  @remValue: round((@pixels / @base-font-size), 3);
    @{selector}: ~"@{remValue}rem";
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}


.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
