@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 250;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Einstellung';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/fonts/NeueEinstellung-ExtraBold.otf) format('opentype');
}
