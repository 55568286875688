.detail-content {
  //position: absolute;
  //bottom: 48px;
  //bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  .blurred-white();
}

.detail-content-title {
  margin-bottom: 24px;
}

.detail-content-item {
  .fs-14;
  line-height: 160%;
  display: flex;
  gap: 4px;

  &-label {
    color: @c-gray-3;
    .fs-14-500;
  }

  &-value {
    color: @c-text-gray;
    .fs-14-700;
  }
}
