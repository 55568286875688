.ant-btn {
  .rem(font-size, 14);
  border-radius: 48px;
  text-transform: uppercase;
  font-weight: 700;

  &.ant-btn-default {
    border-color: @c-primary !important;
    color: @c-primary !important;
  }
}
