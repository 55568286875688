@import "fonts/neue-einstellung";

.fs-8 {
  .rem(font-size, 8);
  .rem(line-height, 10);
}

.fs-10 {
  .rem(font-size, 10);
  .rem(line-height, 14);
}

.fs-12 {
  .rem(font-size, 12);
  line-height: 180%;
}

.fs-14 {
  .rem(font-size, 14);
  .rem(line-height, 20);
}

.fs-16 {
  .rem(font-size, 16);
  line-height: 140%;
}

.fs-17 {
  .rem(font-size, 17);
  line-height: 140%;
}

.fs-18 {
  .rem(font-size, 18);
  line-height: 150%;
}

.fs-20 {
  .rem(font-size, 20);
  line-height: 150%;
}

.fs-22 {
  .rem(font-size, 22);
  line-height: 150%;
}

.fs-24 {
  .rem(font-size, 24);
  line-height: 150%;
}

.fs-26 {
  .rem(font-size, 26);
  line-height: 150%;
}

.fs-26 {
  .rem(font-size, 26);
  .rem(line-height, 140%);
}

.fs-28 {
  .rem(font-size, 28);
  line-height: 150%;
}

.fs-30 {
  .rem(font-size, 30);
  .rem(line-height, 38);
}

.fs-32 {
  .rem(font-size, 32);
  .rem(line-height, 38);
}

.fs-40 {
  .rem(font-size, 40);
  .rem(line-height, 48);
}

.fs-42 {
  .rem(font-size, 42);
  .rem(line-height, 48);
}

.fs-48 {
  .rem(font-size, 48);
  .rem(line-height, 58);
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.bold-16 {
  .fs-16;
  font-weight: 700;
}

.normal-14 {
  .fs-14;
  font-weight: 400;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fs-48-800 {
  .fs-48;
  .fw-800;
}

.fs-48-700 {
  .fs-48;
  .fw-700;
}

.fs-42-700 {
  .fs-42;
  .fw-700;
}

.fs-40-700 {
  .fs-40;
  .fw-700;
}

.fs-40-400 {
  .fs-40;
  .fw-400;
}

.fs-40-200 {
  .fs-40;
  .fw-200;
}

.fs-32-800 {
  .fs-32;
  .fw-800;
}

.fs-32-700 {
  .fs-32;
  .fw-700;
}

.fs-28-700 {
  .fs-28;
  .fw-700;
}

.fs-26-700 {
  .fs-26;
  .fw-700;
}

.fs-24-400 {
  .fs-24;
  .fw-400;
}

.fs-24-600 {
  .fs-24;
  .fw-600;
}

.fs-24-700 {
  .fs-24;
  .fw-700;
}

.fs-24-800 {
  .fs-24;
  .fw-800;
}

.fs-22-700 {
  .fs-22;
  .fw-700;
}

.fs-20-600 {
  .fs-20;
  .fw-600;
}

.fs-20-700 {
  .fs-20;
  .fw-700;
}

.fs-18-700 {
  .fs-18;
  .fw-700;
}

.fs-18-400 {
  .fs-18;
  .fw-400;
}

.fs-16-400 {
  .fs-16;
  .fw-400;
}

.fs-16-500 {
  .fs-16;
  .fw-500;
}

.fs-16-700 {
  .fs-16;
  .fw-700;
}

.fs-16-600 {
  .fs-16;
  .fw-600;
}

.fs-14-700 {
  .fs-14;
  .fw-700;
}

.fs-14-400 {
  .fs-14;
  .fw-400;
}

.fs-14-300 {
  .fs-14;
  .fw-300;
}

.fs-14-500 {
  .fs-14;
  .fw-500;
}

.fs-14-600 {
  .fs-14;
  .fw-600;
}

.fs-12-200 {
  .fs-12;
  .fw-200;
}

.fs-12-400 {
  .fs-12;
  .fw-400;
}

.fs-12-500 {
  .fs-12;
  .fw-500;
}

.fs-12-600 {
  .fs-12;
  .fw-600;
}

.fs-12-700 {
  .fs-12;
  .fw-700;
}

.fs-10-700 {
  .fs-10;
  .fw-700;
}

.fs-10-600 {
  .fs-10;
  .fw-600;
}

.fs-10-400 {
  .fs-10;
  .fw-400;
}
