
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "assets/styles/index";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */

@height-lg: 48px;
@error-color: @c-danger;
@primary-color: @c-primary;
@nav-height: 56px;
@padding-base: 24px;

.ant-drawer-mask {
  background-color: transparent;
}

.ant-drawer-right > .ant-drawer-content-wrapper.mobile-menu-drawer {
  width: 100%;
  top: @nav-height;
  box-shadow: none;

 .ant-drawer-body {
   padding: 40px 24px;
 }
}

@media screen and (min-width: 900px) {
  .ant-drawer-right > .ant-drawer-content-wrapper.mobile-menu-drawer {
    max-width: 300px;
  }
}

.blurred-white {
  background: @c-white-75;
  backdrop-filter: blur(10px);
  z-index: 1;
}

