.ant-form-item-label {
  padding-bottom: 4px !important;

  & > label {
    line-height: 160%;
    height: unset;
    color: @c-gray-4;
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
}
