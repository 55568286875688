.ant-tabs-tab {
  padding: 16px;

  button {
    .fs-14-700;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
  z-index: 1;
  //background-color: @c-white-100;
  height: @nav-height;
}

.ant-tabs-content, .ant-tabs-tabpane {
  height: 100%;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-content-holder {
  overflow-y: auto;

}
